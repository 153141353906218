:root {
  --animation-duration: 1s;
  --animation-rep: infinite;
}

.App {
  text-align: center;
}

.note-container img {
  width: 100% !important;
  height: auto;
}

body {
  font-family: 'AvenirNext';
  font-family: 'HelviticaNue';
}

strong {
  /* font-family: "AvenirNext"; */
  font-family: 'HelviticaNue-Bold';
}

html {
  font-size: 100%;
}

#input[type='file'] {
  display: none;
}

.image-upload {
  cursor: pointer;
}

.fullscreen-enabled {
  background: #f7f6fa;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.fullscreen-img {
  background: #f7f6fa;
  padding-bottom: 0px;
  background-image: url('./assets/images/assessment/QuestionSVG.svg');
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.custom-class {
  background-color: pink;
}

.css-oj8kmw {
  overflow-y: auto;
}

.tox:not(.tox-tinymce-inline) .tox-editor-header {
  bottom: 0px !important;
  position: absolute;
}

.Wirisformula {
  width: 500px !important;
  max-width: 1000px !important;
}

.note-container {
  font-family: Inter !important;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  color: #11263c;
  margin-left: 10px;
}

.fc-content p {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 21px !important;
  font-family: Inter !important;
  color: black !important;
  font-weight: 400 !important;
}

@media screen and (max-width: 767px) {
  .MuiTabPanel-root {
    padding: 0px !important;
  }

  .MuiAccordionSummary-root {
    padding: 0px !important;
    margin: 0px 5px !important;
  }

  .MuiAccordionDetails-root {
    padding: 0px !important;
  }

  .note-container {
    padding-left: 30px !important;
    padding-right: 20px !important;
    color: #11263c;

    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  .note-container-flashcard-mobile {
    color: var(--black, #11263c) !important;
    text-align: center !important;
    font-family: Satoshi !important;
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 28px !important;
    /* padding-left: 30px !important;
    padding-right: 20px !important;
    color: #11263c;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Satoshi !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: normal; */
  }
  .fullscreen-enabled {
    background: #f7f6fa;
    padding-bottom: 20px;
  }
}

.picker .react-date-picker__inputGroup {
  display: none;
}

.picker .react-date-picker__wrapper {
  background-color: white !important;
}

.picker .react-date-picker__button {
  padding: 0 !important;
}

@keyframes wordo {
  0% {
    fill: rgba(0, 0, 0, 0.212);
  }
  11% {
    fill: #e015a2;
  }

  100% {
    fill: #e015a2;
  }
}

@keyframes wordc {
  0% {
    fill: rgba(0, 0, 0, 0.212);
  }
  11% {
    fill: rgba(0, 0, 0, 0.212);
  }
  21% {
    fill: #e015a2;
  }

  100% {
    fill: #e015a2;
  }
}

@keyframes wordt {
  0% {
    fill: rgba(0, 0, 0, 0.212);
  }
  21% {
    fill: rgba(0, 0, 0, 0.212);
  }
  31% {
    fill: #e015a2;
  }

  100% {
    fill: #e015a2;
  }
}

@keyframes wordi {
  0% {
    fill: rgba(0, 0, 0, 0.212);
  }
  31% {
    fill: rgba(0, 0, 0, 0.212);
  }
  41% {
    fill: #e015a2;
  }

  100% {
    fill: #e015a2;
  }
}
@keyframes wordl {
  0% {
    fill: rgba(0, 0, 0, 0.212);
  }
  41% {
    fill: rgba(0, 0, 0, 0.212);
  }
  51% {
    fill: #624ba2;
  }

  100% {
    fill: #624ba2;
  }
}
@keyframes worde {
  0% {
    fill: rgba(0, 0, 0, 0.212);
  }
  51% {
    fill: rgba(0, 0, 0, 0.212);
  }
  61% {
    fill: #624ba2;
  }

  100% {
    fill: #624ba2;
  }
}
@keyframes worda {
  0% {
    fill: rgba(0, 0, 0, 0.212);
  }
  61% {
    fill: rgba(0, 0, 0, 0.212);
  }
  71% {
    fill: #624ba2;
  }

  100% {
    fill: #624ba2;
  }
}
@keyframes wordr {
  0% {
    fill: rgba(0, 0, 0, 0.212);
  }
  71% {
    fill: rgba(0, 0, 0, 0.212);
  }
  81% {
    fill: #624ba2;
  }

  100% {
    fill: #624ba2;
  }
}

@keyframes wordn {
  0% {
    fill: rgba(0, 0, 0, 0.212);
  }
  81% {
    fill: rgba(0, 0, 0, 0.212);
  }

  91% {
    fill: #624ba2;
  }
  100% {
    fill: #624ba2;
  }
}
@keyframes mainanimation {
  0% {
    fill: rgba(0, 0, 0, 0.212);
  }
  100% {
    fill: url(#paint0_linear_5938_1064);
  }
}

@keyframes stop1 {
  0% {
    stop-color: rgba(0, 0, 0, 0.212);
  }
  25% {
    stop-color: rgba(129, 73, 125, 0.336);
  }
  50% {
    stop-color: #a04a86d0;
  }
  70% {
    stop-color: #e015a2;
  }
  100% {
    stop-color: #e015a2;
  }
}
@keyframes stop2 {
  0% {
    stop-color: rgba(0, 0, 0, 0.212);
  }
  25% {
    stop-color: rgba(76, 84, 131, 0.212);
  }

  50% {
    stop-color: #655d7c;
  }
  80% {
    stop-color: #624ba2;
  }
  100% {
    stop-color: #624ba2;
  }
}
#main {
  animation: mainanimation var(--animation-duration) var(--animation-rep);
}
.stop1 {
  animation: stop1 var(--animation-duration) var(--animation-rep);
}
.stop2 {
  animation: stop2 var(--animation-duration) var(--animation-rep);
}
.o {
  animation: wordo var(--animation-duration) var(--animation-rep);
}
.c {
  animation: wordc var(--animation-duration) var(--animation-rep);
}
.t {
  animation: wordt var(--animation-duration) var(--animation-rep);
}
.i {
  animation: wordi var(--animation-duration) var(--animation-rep);
}
.l {
  animation: wordl var(--animation-duration) var(--animation-rep);
}
.e {
  animation: worde var(--animation-duration) var(--animation-rep);
}
.a {
  animation: worda var(--animation-duration) var(--animation-rep);
}
.r {
  animation: wordr var(--animation-duration) var(--animation-rep);
}
.n {
  animation: wordn var(--animation-duration) var(--animation-rep);
}

/* Remove number input arrows */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.myList {
  padding: 0px 12px;
}

.wave-container {
  display: flex;
  gap: 5px;
}

.bar {
  width: 5px;
  height: 16px;
  background: #888888;
  border-radius: 20px;
  animation: wave 0.8s infinite ease-in-out;
}

.bar:nth-child(1) {
  animation-delay: 0s;
}

.bar:nth-child(2) {
  animation-delay: 0.1s;
}

.bar:nth-child(3) {
  animation-delay: 0.2s;
}

.bar:nth-child(4) {
  animation-delay: 0.3s;
}

@keyframes wave {
  0%,
  100% {
    transform: scaleY(0.5);
  }

  50% {
    transform: scaleY(1.2);
  }
}

.google-signin-container iframe {
  width: 100% !important;
}
